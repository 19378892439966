import React from "react";
import localize from "../components/_global/localization/localize";
import { graphql } from "gatsby";
import Layout from "../components/_global/layout/Layout";
import PageHeader from "../components/_global/layout/PageHeader";
import Section from "../components/_global/layout/Section";
import Block from "../components/_global/editorial/Block";
import Spacer from "../components/_global/layout/Spacer";
import Group from "../components/4_foundation/Group";
// import Press from "../components/4_foundation/Press";

const Foundation = ({ data, ...props }) => {
	const fData = data.sanityFoundation;

	const people = fData.people.map((item, index) => {
		return (
			<React.Fragment key={index}>
				<Group data={item} key={index} />
				{index < fData.people.length - 1 ? <Spacer s="large" /> : null}
			</React.Fragment>
		);
	});

	return (
		<Layout
			title={fData.common.title}>
			< PageHeader
				title={fData.common.title}
			/>

			<Section m="medium">
				<Block data={fData.text} />
				<Spacer s="large" />
			</Section>

			{people}

			<Spacer s="large" />

			{/*<Section m="medium">*/}
			{/*	<Block data={fData.pressText} />*/}
			{/*	<Spacer s="large" />*/}
			{/*</Section>*/}

			{/*<Press data={fData.press}/>*/}

			{/*<Spacer s="large" />*/}

		</Layout>
	);
};

export default localize(Foundation);

export const query = graphql`
    {
        sanityFoundation {
            common {
                link
                title {
                    _type
                    fr
                }
            }
            text {
                ...RegularBlockFragment
            }
            people {
                title {
                    _type
                    fr
                }
                description {
                    _type
                    fr
                }
                persons {
                    ...PersonFragment
                }
            }
            pressText {
                ...RegularBlockFragment
            }
            press {
                title {
                    _type
                    fr
                }
                items {
                    ... on SanityPressFile {
                        title {
                            _type
                            fr
                        }
                        file {
                            asset {
                                url
                            }
                        }
                    }
                    ... on SanityPressLink {
                        title {
                            _type
                            fr
                        }
                        link
                    }
                }
            }
        }
    }
`;
