import React from "react";
import styled from "styled-components";
import Section from "../_global/layout/Section";
import Text from "../_global/editorial/Text";
import Spacer from "../_global/layout/Spacer";
import Person from "../_global/Person";

const Comp = styled(Section)`
`;

const People = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

const Group = (props) => {
	const { data } = props;
	console.log(data);

	const people = data.persons.map((item, index) => {
			return (
				<React.Fragment key={index}>
					<Person data={item} partners={data.title === "Partenaires"}/>
					{/*{index < data.persons.length - 1 ? <Spacer s="medium" /> : null}*/}
				</React.Fragment>
			);
		}
	);

	return (
		<Comp m="medium">
			<Text s="h2" c="main01">
				{data.title}
			</Text>
			<Spacer s="medium" />

			{data.description ?
				<>
					<Text s="medium">{data.description}</Text>
					<Spacer s="medium" />
				</>
				: null}


			<People>
				{people}
			</People>
		</Comp>
	);
};

export default Group;
