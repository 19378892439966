import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import AltImage from "./AltImage";
import Text from "./editorial/Text";
import Spacer from "./layout/Spacer";
import Link from "./localization/Link";

const Comp = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: fit-content;
  align-items: center;
  max-width: ${p => p.partners ? "30%" : "12.5rem"};
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2rem;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    //max-width: 8rem;
    max-width: ${p => p.partners ? "100%" : "12.5rem"};
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

const Portrait = styled(AltImage)`
  max-width: 12.5rem;
  max-height: 12.5rem;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 1rem;
  overflow: hidden;

  img {
    border-radius: 50%;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    margin-bottom: 0.5rem;
  }
`;

const Person = (props) => {
	const { data, partners } = props;
	// console.log(data);
	return (
		<Comp partners={partners}>
			<Portrait image={data.photo} alt={data.firstName + " " + data.lastName} />

			<Text s="medium" style={{ textTransform: "uppercase" }} w="bold">
				{data.firstName}
				<br />
				{data.lastName}
			</Text>

			<Text s="medium">
				{data.role}
			</Text>

			{data.link ?
				<Link to={data.link.href} className="contentLink">
					<Text s="medium">
						{data.link.title}
					</Text>
				</Link>
				: null}
			<Spacer s="small" />

			{data.description ?
				<Text s="medium">
					{data.description}
				</Text> : null}
		</Comp>
	);
};

export default Person;

export const query = graphql`
    fragment PersonFragment on SanityPerson {
        lastName
        firstName
        role {
            _type
            fr
        }
        description {
            _type
            fr
        }
        link {
            ...LinkFragment
        }
        photo {
            asset {
                gatsbyImageData(fit: CLIP, placeholder: DOMINANT_COLOR)
            }
        }
    }
`;
